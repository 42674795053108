

/* .newsTitle{

} */

.newsContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    margin:0;
    padding:0;
    width: 100%;
  }

  .newsTitleContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .newsTitle {
    margin-top: 69px; /* Remove default margin */
    margin-bottom:0px;
    font-family: 'customOPPOSans'; 
    font-size: 40px; 
  }

  .newsDate{
    margin-top:17px;
    color:#A5A5A5;
    font-family: 'customOPPOSans';

  }

  .newsContent {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: start; /* Center items horizontally */
    margin: 96px 323px 122px; /* Set margins (top, left/right, bottom) */
    max-width: calc(100% - 646px); /* Optional: Ensure it doesn't exceed container width */
  }
  
  .newsContent > * {
    width: 100%; /* Make child elements take full width */
    text-align: start; /* Center text inside child elements */
  }

  @media only screen and (max-width: 767px){
    .newsTitle{
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin-top: 37px;
    }
    .newsDate{
      font-size:14px;
    }

    .newsContent {
      align-items: start; /* Center items horizontally */
      margin: 0px 20px 20px; /* Set margins (top, left/right, bottom) */
      display: block; /* Change display to block on smaller screens */
      max-width: none;
    }

    .newsContent > * {
      width: 100%; /* Make child elements take full width */
      text-align: start; /* Center text inside child elements */
    }

  }