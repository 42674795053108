.sign-up-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "customOPPOSans";
  @media only screen and (max-width: 768px) {
    padding-left: 45px;
    width: 90%;
  }

  .heading {
    margin-top: 80px;
    color: #9a1e23;
    font-size: 66px;
    font-weight: bold;
    margin-bottom: 50px;
    @media only screen and (max-width: 768px) {
      font-size: 36px;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .team-information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    @media only screen and (max-width: 768px) {
      font-size: 26px;
      align-items: center;
    }

    .sub-heading {
      margin-bottom: 30px;
      color: #9a1e23;
      font-weight: bold;
      font-size: 34px;
      @media only screen and (max-width: 768px) {
        font-size: 26px;
        text-align: center;
      }
    }

    .team-members-information-heading3 {
      margin-bottom: 30px;
      color: #9a1e23;
      font-size: 32px;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        text-align: left;
        width: 100%;
      }
    }

    .member-separator {
      border: none;
      border-top: 2px solid #6f6e6f;
      margin: 50px 0;
      width: 100%;
      @media only screen and (max-width: 768px) {
        margin-top: 26px;
      }
    }

    .middle-button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      flex-direction: column;
      align-items: center;

      .add-member-button {
        margin-top: 10px;
        background-color: #9a1e23;
        color: #fff;
        border: none;
        padding: 10px 100px;
        cursor: pointer;
        border-radius: 8px;
        font-size: 22px;
        @media only screen and (max-width: 768px) {
          width: 100%;
          padding: 10px 5px;
          margin-top: 0px;
          margin-bottom: 50px;
        }
      }
    }

    .remove-member-button {
      margin-top: 10px;
      background-color: #9a1e23;
      color: #fff;
      border: none;
      padding: 8px 30px;
      cursor: pointer;
      border-radius: 8px;
      font-size: 20px;
      @media only screen and (max-width: 768px) {
        margin-left: auto;
      }
    }

    .team-information-heading {
      margin-bottom: 30px;
      color: #9a1e23;
      font-weight: bold;
      font-size: 34px;
      @media only screen and (max-width: 768px) {
        font-size: 26px;
        text-align: center;
      }
    }

    .first {
      margin-bottom: 80px;
    }

    .error-message {
      color: #9a1e23;
      font-size: 12px;
      margin-top: 5px;
    }

    .team-information-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .input-row {
        display: flex;
        margin-bottom: 15px;
        width: 100%;
        gap: 80px;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          gap: 20px;
        }
        .empty-space {
          flex: 1;
        }
      }

      .field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        flex: 1;
      }

      .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;

        .rounded-input {
          width: 100%;
          padding: 10px;
          border: 2px solid #9a1e23;
          border-radius: 6px;
          box-sizing: border-box;
          font-size: 16px;
          outline: none;

          &::placeholder {
            font-size: 18px;
            color: #aaa;
          }

          &:focus {
            border-color: #9a1e23;
            box-shadow: 0 0 5px rgba(154, 30, 35, 0.5);
          }
        }

        .option-bar {
          padding: 10px;
          border: 2px solid #9a1e23;
          border-radius: 6px;
          font-size: 18px;
          outline: none;
          color: #aaa;

          .option {
            background-color: #c95c5c;
            color: white;
          }
        }

        .has-value {
          color: #333; /* 修改选项的字体颜色 */
        }

        .input-heading {
          font-size: 24px;
          margin-bottom: 5px;
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        .file-upload {
          width: 100%;
        }

        .file-upload-container {
          position: relative;

          .upload-button {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: 10px 15px;
            background-color: #9a1e23;
            color: #fff;
            border: none;
            border-radius: 0 10px 10px 0;
            cursor: pointer;
            height: 100%;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-wrap: nowrap;
            @media only screen and (max-width: 768px) {
              border-radius: 10px;
              width: 100%;
              font-size: 20px;
            }
            .file-input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }

        .upload-progress {
          top: 70%;
          position: relative;
          margin-left: 10px;
          background-color: #f3f3f3;
          border-radius: 5px;
          overflow: hidden;
          height: 5px;
          @media only screen and (max-width: 768px) {
            display: none;
          }

          .upload-progress-bar {
            height: 100%;
            background-color: #9a1e23;
            transition: width 0.3s;
          }
        }
      }
    }
  }

  .attention {
    font-size: 24px;
    color: #858585;
    margin-bottom: 80px;
    width: 80%;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      width: 95%;
    }

    .attention-content {
      @media only screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }

  .last-attention {
    font-size: 24px;
    color: #858585;
    margin-bottom: 80px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .other-information {
    width: 100%;
    @media only screen and (max-width: 768px) {
      margin-bottom: 30px;
    }

    .heading-3 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    .other-information-block {
      margin-bottom: 50px;
      .other-information-input {
        width: 100%;
        padding: 10px;
        border: 2px solid #9a1e23;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        outline: none;
        height: 150px;

        @media only screen and (max-width: 768px) {
          margin-bottom: 10px;
        }

        &::placeholder {
          font-size: 18px;
          color: #aaa;
        }

        &:focus {
          border-color: #9a1e23;
          box-shadow: 0 0 5px rgba(154, 30, 35, 0.5);
        }
      }
    }
  }

  .submit-button {
    margin-top: 10px;
    background-color: #9a1e23;
    color: #fff;
    border: none;
    padding: 8px 66px;
    cursor: pointer;
    border-radius: 40px;
    font-size: 38px;
    margin-bottom: 20px;

    @media only screen and (max-width: 768px) {
      padding: 8px 40px;
      font-size: 24px;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: #fff;
    color: #9a1e23;
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 90%;
    max-width: 600px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-button {
    float: right;
    background: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    line-height: 20px;
    padding: 5px 10px;
    margin: -10px -10px 10px 0;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.countdown-page {
  display: flex;
  font-family: "customOPPOSans";
  align-items: center;
  justify-content: center;
  height: 580px;
  background-image: url("../img/comingsoonbg.png");
  background-position: right;
  background-size: 60% auto;
  background-repeat: no-repeat;
  @media only screen and (max-width: 768px) {
    background-size: 130% auto;
    background-position: -60%;
  }
  .message {
    font-weight: bold;
    font-size: 46px;
    color: #9a1e23;
    text-align: center;
    @media only screen and (max-width: 768px) {
      font-size: 22px;
    }

    .home-link {
      color: #9a1e23; /* 根据需求调整颜色 */
      cursor: pointer;
      position: relative;
      text-decoration: none; /* 移除默认下划线 */
    }

    .home-link::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px; /* 控制下划线的垂直位置，可以根据需要调整 */
      height: 3px; /* 下划线的粗细 */
      background-color: #9a1e23; /* 下划线颜色，与文字颜色保持一致 */
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    .home-link:hover::after {
      transform: scaleX(1);
    }
  }
}
