.aboutus-wrap {
  width: 100%;
  background: url('../img/about-us-bg.png') no-repeat left top;
  background-size: contain;
  display: flex;
  overflow-x: hidden;
  padding-top: 90px;
  
  .tab-container {
    width: 342px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 55px;
    
    .logo img {
      width: 180px;
      height: auto;
    }
    
    .tab-list {
      font-family: 'customOPPOSans', sans-serif; // 设置字体
      .tab-item {
        position: relative; // 为伪元素定位做准备
        color: #000000;
        font-size: 21.6px;
        height: 58.5px; // 可调整为适当的高度以适应新的 line-height
        line-height: 58.5px; // 调整为需要的值以适应文本间隔
        width: 144px; // 根据需要调整
        text-align: center;
        cursor: pointer;
        padding-top: 5px; // 增加上部间隔
        padding-bottom: 3px; // 增加下部间隔
    
        &.active {
          &::after {
            content: ''; // 必须有，即使是空字符串
            position: absolute;
            left: 50%; // 从中间开始
            bottom: 0; // 定位到底部
            width: 180px; // 伪元素的宽度
            height: 1px; // 伪元素的高度
            background-color: #9A1E23; // 边框颜色
            transform: translateX(-50%); // 中心对齐
          }
          border-bottom: none; // 移除原本的border-bottom
        }
      }
    }
    
  }

  .member-container {
    flex: 1;
    
    .header {
      color: #9A1E23;
      font-size: 57.6px;
      margin-bottom: 67.5px;
      font-family: 'customOPPOSans';
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        font-size: 40px;
        margin-left: 50px;
        margin-bottom: 10px;
      }
    }
    
    .title {
      font-size: 36px;
      font-weight: 400;
      display: flex;
      align-items: center;
      font-family: 'customOPPOSans';
      margin-bottom: 34.2px;
      @media only screen and (max-width: 768px) {
        margin-left: 50px;
      }
      &::before {
        content: "";
        width: 7.2px;
        height: 49.5px;
        margin-right: 22px;
        background: #C90;
        @media only screen and (max-width: 768px) {
          width: 4px;
          margin-right: 10px;
        }
      }
    }
    
    .association-member-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -100px;
      left: 100px;
      position: relative;
      @media only screen and (min-width: 411px) and (max-width: 768px) {
          margin-left: 50px;
          left: 0px;
          position: relative;
      }      
      @media only screen and (max-width: 410px) {
        margin-left: -50px;
        left: 100px;
        position: relative;
      }
      .member-item {
        width: 260px;
        margin-bottom: 45px;

        @media only screen and (max-width: 768px) {
          width: 180px; // 在手机模式下，宽度占据整个父容器的宽度
          margin-bottom: 30px; // 调整底部间距
        }

        .avatar-outer {
          width: 193px;
          height: 265px;          
          background: #D9D9D9;
          margin-bottom: 9px;

          img {
            width: 100%; // 确保图片填满容器
            height: auto;
            display: block;
          }
        }
        
        .name {
          color: #036;
          font-size: 21.6px;
          line-height: 36px;
          border-bottom: 3px solid #C90;
          width: 126px;
          @media only screen and (max-width: 768px) {
            top: -10px;
            position: relative;
            font-size: 19px; // 在手机模式下调整顶部间距
          }
        }

        .job {
          color: #036;
          font-size: 21.6px;
          line-height: 36px;
          font-family: 'customOPPOSans';
          @media only screen and (max-width: 768px) {
            margin-top: 30px;
            font-size: 19px; // 在手机模式下调整顶部间距
          }
        }

        .introduce1 {
          font-size: 17px;
          line-height: 27px;
          margin-top: 10px;
          max-width: 200px;
          font-family: 'customOPPOSans';
          line-height: 20px;
          @media only screen and (max-width: 768px) {
            font-size: 15px;
            top: -10px;
            position: relative;
          }
          &:not(:last-child) {
            margin-bottom: 0; // 移除除最后一个介绍外的底部边距
          }
        }

        .introduce2 {
          font-size: 17px;
          line-height: 27px;
          max-width: 200px;
          font-family: 'customOPPOSans';
          line-height: 20px;
          @media only screen and (max-width: 768px) {
            font-size: 15px;
            top: -10px;
            position: relative;
          }
          &:not(:last-child) {
            margin-bottom: 0; // 移除除最后一个介绍外的底部边距
          }
        }
      }
    }

    .previous-member-list {
      margin-left: 30px;
      @media only screen and (max-width: 768px) {
        margin-left: 50px;
      }
      .member-item {
        .stage {
          color: #003366;
          font-size: 32.4px;
          display: flex;
          font-family: 'customOPPOSans';
          align-items: center;
          @media only screen and (max-width: 768px) {
            font-size: 24px;
          }
          &::before {
            content: "";
            width: 16.2px;
            height: 16.2px;
            background: #003366;
            margin-right: 32px;
            @media only screen and (max-width: 768px) {
              width: 12px;
              height: 12px;
              margin-right: 20px;
            }
          }
        }
        .introduce {
          font-size: 21.6px;
          color: #000000;
          margin: 27px 45px;
          font-family: 'customOPPOSans';
          @media only screen and (max-width: 768px) {
            font-size: 19px;
            margin: 15px 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1080px) {
  .aboutus-wrap {
    justify-content: center; // 将整体内容居中对齐
  }
}

.member-info {
  .name {
    white-space: nowrap; // 防止文本换行
    max-width: 150px; 
    font-family: 'customOPPOSans';
  }
}

.past-members-section {
  margin-top: 60px;
  padding-bottom: 200px;
  @media only screen and (max-width: 768px) {
    margin-top: 30px;
  }
}
