.column {
    display: flex;
    flex-direction: column;
    flex: 1; /* 平均分配宽度 */
    padding: 0 10px;
}

.column ul {
    list-style-type: none; /* 移除列表默认样式 */
    padding: 0;
}

.column ul li {
    margin-bottom: 10px; /* 可选：增加项目之间的间距 */
}

a.nav-link {
    margin-left: 45px; /* 增加链接之间的间距 */
    padding: 0px;
    color: #9A1E23; /* 字体颜色 */
    font-size: 20px; /* 字号大小 */
    font-family: "customOPPOSans"; /* 字体 */
    font-weight: 400;
    word-wrap: break-word; /* 文字自动换行 */
    text-decoration: none; /* 去掉下划线 */
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.grey-bg {
    height: 53px;
    max-width: 100%;
}

.Logo, .Title {
    height: 60%;
    margin-right: 15px;
    padding-right: 20px;
    border-right: 1px solid #003366;
}

.tp-header-height {
    height: 150px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tab-box {
    height: 96px;
}

.tab-box-item {
    margin-left: 50px;
    width: calc(100% - 50px);
    max-width: 100vw;
    max-height: 100vh;
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* 使导航链接靠右对齐 */
    flex-grow: 2;
    white-space: nowrap;
}

.Earth {
    margin-right: 100px; /* 增加地球图标和文本之间的距离 */
    align-self: flex-end; /* 确保地球图标靠右对齐 */
}

.menubtn {
    width: 23px;
    height: 23px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 0; /* 默认隐藏，小屏显示 */
    transition: opacity 0.3s ease; /* 平滑过渡 */
}

.menubtn span, .menubtn span::before, .menubtn span::after {
    display: block;
    width: 19px;
    height: 2px;
    background: #9A1E23;
    position: relative;
    transition: all 0.3s ease; /* 平滑过渡所有属性 */
}

.menubtn span::before {
    top: -6px;
}

.menubtn span::after {
    bottom: -6px;
}

@media only screen and (max-width: 1400px) {
    .tp-header-height {
        height: auto;
    }

    .tab-box {
        height: 52px;
        background: #FFF;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }

    .grey-bg {
        height: 24px;
        font-size: 12px;
    }

    .nav-bar {
        display: none;
    }

    .Logo, .Title {
        border-right: 1px solid #003366;
        margin-right: 5px;
        padding-right: 5px;
    }

    .tab-box-item {
        margin-left: 15px;
        width: calc(100% - 30px);
    }

    .menubtn {
        opacity: 1; /* 在小屏幕上显示菜单按钮 */
    }
}

.nav-link-container {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: block; /* 默认不显示 */
    position: absolute;
    background-color: #C95C5C; /* 矩形框颜色 */
    z-index: 1;
    border: none; /* 去除边框 */
    top: 100%;
}

.nav-link-container:hover .dropdown-menu {
    display: block; /* 悬停显示 */
    background-color: #C95C5C; /* 确保背景色一致 */
    z-index: 2000;
}

.nav-link-container .dropdown-menu .dropdown-item {
    color: white;
    font-family: "customOPPOSans";
}


.nav-link-container .dropdown-menu .dropdown-item:hover {
    background-color: white; /* 悬停时背景色 */
    color: #C95C5C; /* 悬停时字体颜色为白色 */
}

.nav-link-container .dropdown-menu .dropdown-item.active,
.nav-link-container .dropdown-menu .dropdown-item:active {
    background-color: white;; /* 被点击时的背景颜色 */
    color: #9A1E23; /* 被点击时的字体颜色 */
}

.menubtn {
    width: 20px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    position: relative; /* 增加定位属性 */
}

.menubtn span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #9A1E23;
}

.side-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100vh;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto;
}

.side-menu.open {
    transform: translateX(0);
    z-index: 60;
    margin-top: 70px;
}

.mobile-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 200px;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
}

.mobile-menu.open {
    right: 0;
}

.mobile-nav {
    padding: 20px;
}

.mobile-nav-link-container {
    margin-bottom: 10px;
}

.mobile-nav-link {
    display: flex;
    align-items: center;
    color: #9A1E23;
    font-family: "customOPPOSans";
    font-weight: bold;
    text-decoration: none;
    padding: 10px 20px;
}

.mobile-nav-link-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mobile-dropdown-menu {
    background-color: #f9f9f9;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-left: 3px solid #9A1E23;
    padding: 5px 0;
}

.mobile-dropdown-item {
    display: block;
    padding: 8px 20px;
    color: #9A1E23;
    text-decoration: none;
    font-family: "customOPPOSans";
    font-weight: bold;
}

.about-dropdown-icon {
    transform: rotate(-90deg); /* 初始角度 */
    transition: transform 0.3s ease;
}

.about-dropdown-icon.rotate {
    transform: rotate(0deg);
}

.language-dropdown-icon {
    display: inline-block;  /* Ensures that transform can be applied */
    transform: rotate(-90deg);  /* Default position */
    transition: transform 0.3s ease;  /* Smooth transition */
}

.rotate {
    transform: rotate(0deg);  /* Rotate the icon when active */
}

.alumni-dropdown-icon {
    display: inline-block;  /* 确保可以应用转换 */
    transform: rotate(-90deg);  /* 默认位置 */
    transition: transform 0.3s ease;  /* 平滑的转换动画 */
}

.about-dropdown-icon.rotate, .language-dropdown-icon.rotate, .alumni-dropdown-icon.rotate {
    transform: rotate(0deg);
}

.tp-header-height {
    position: fixed; /* 设置位置固定 */
    top: 0;          /* 距顶部0距离 */
    width: 100%;     /* 宽度设为100%，覆盖整个视口宽度 */
    z-index: 500; 
}

