/*社会顾问*/
.SocialAdvisor{
	background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
	background: url('../img/about-us-bg.png') no-repeat left top;
	background-size: 100%;
	padding:0 150px;
	box-sizing: border-box;
	padding-bottom: 150px;

	.title{
		color: #9A1E23;
		font-size: 64px;
		font-weight: 500;
		margin-bottom: 55px;
		padding-top: 70px;
		font-family: "customOPPOSans";
	}
	
	.lists{
		.lists-item{
			display: flex;
			width:100%;
			margin-bottom:113px;
			@media only screen and (max-width: 767px){
				margin-bottom: 0px;
			}
			.right{
				flex:1;
			}
		}
		
		img{
			width: 234px;
			height: 318px;
			margin-right:81px;
		}
		
		.name{
			color: #036;
			font-family: "customOPPOSans";
			font-size: 36px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding-left: 15px;
		}
		
		.job{
			color: #036;
			font-family: "customOPPOSans";
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding-left: 15px;
		}
		
		.bottom_content{
			position: relative;
		}
		
		.bottom_content::before{
			position: absolute;
			content: "";
			width: 3px;
			height: 78.006px;
			display: block;
			top: 5px;
			background-color: #C90;
		}
		
	} 
	
	.label{
		color: #252525;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 28px; 
		font-family: "customOPPOSans";
		margin-top: 36px;
		width:100%;
		white-space:pre-wrap;
	}
}

@media only screen and (max-width: 767px){
	.SocialAdvisor{
		padding: 0 38px;
		box-sizing: border-box;
		overflow: hidden;
		background: url('../img/about-us-bg.png') no-repeat left top;
		background-size: 100%;
		
		.title{
			font-size: 32px;
			margin-bottom: 44px;
			padding-top: 60px;
		}
		
		.lists{
			margin-bottom: 60px;
			
			img{
				width: 100px;
				height: 136px;
				margin-right: 28px;
				margin-bottom: 26px;
			}
			
			.lists-item{
				display: flex;
				
				.name{
					font-size: 24px;
				}
				
				.job{
					font-size: 20px;
				}
				
				.bottom_content{
					&::before{
						height: 56px;
						width: 3px;
						top: 5px;
					}
				}
			}
			
			.label{
				margin-top: 0px;
				font-size: 16px;
				width: auto;
				line-height: 1.4;
			}
		}
	}
}

.small-text {
	font-size: smaller; /* 或者具体的字体大小，例如 font-size: 0.8em; */
}

.special-item .bottom_content::before {
    height: 215px !important;
	@media only screen and (max-width: 767px){
		height: 150px !important;
	}
}